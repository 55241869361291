<template>
  <!-- Scrollable modal -->
  <div
    class="modal fade text-center"
    id="modal"
    tabindex="-1"
    aria-labelledby="modal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-xl">
      <div class="modal-content">
        <div class="modal-header border-0">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body pb-5">
          <h1 class="fs-1 pt-3 pb-3">{{ content.title }}</h1>
          <p>{{ content.description }}</p>
          <h2 class="mt-5 mb-3">Tech Stack 📚</h2>
          <div>
            <img
              class="p-1"
              v-for="tech in content.tech"
              :key="tech"
              :src="tech"
              alt="사용기술"
            />
          </div>
          <h2 class="mt-5 mb-3">Demo</h2>
          <div class="w-100">
            <a
              v-if="content.url"
              class="d-block p-2 btn btn-secondary text-white w-50 mx-auto mb-3"
              :href="content.url"
              target="_blank"
              >데모 사이트 보러가기</a
            >
          </div>
          <video
            v-if="content.video"
            :src="require(`@/assets/video/${content.video.name}.mp4`)"
            controls
            autoplay
            muted
            :style="'width:' + content.video.width + '%; object-fit: cover;'"
          ></video>
          <img 
            v-if="content.picture"
            :src="require(`@/assets/image/${content.picture.url}`)"
            :style="'width:' + content.picture.width + '%; object-fit: cover;'"
            alt="picture">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default() {
        return {
          title: 'title',
          description: '',
          tech: [],
        };
      },
    },
  },
};
</script>

<!-- <style scoped>
  video {
    width: 100%;
    object-fit: cover;
  }
</style> -->
