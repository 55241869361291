<template>
  <footer class="d-flex justify-content-between align-items-center">
    <div>
      <a href="https://sugared-carrot-745.notion.site/f86c7f236bf440d9b3b2828492002fd4" target="_blank" class="mr-1">Resume</a>
      <a class="mr-1" href="https://github.com/Rosie-bb" target="_blank">Github</a>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <span class="mr-1">© 2023 hyemin</span>
      <img src="@/assets/image/smile.svg" alt="smile icon">
    </div>
  </footer>
</template>