<template>
  <main>
    <div class="container">
      <!-- 최상단 소개 -->
      <section class="wrap-header">
        <h1 class="fs-0">Really, Fun</h1>
        <div class="imgs">
          <img
            src="@/assets/image/hyemin.svg"
            alt="profile"
          />
          <div class="sub-title">
            <span class="fs-0 f-border">#긍정</span>
            <span class="fs-0 f-border">#협업</span>
          </div>
        </div>
        <span class="fs-6">Web Developer / UI & Markup Developer</span>
      </section>

      <!-- 소개글 -->
      <section class="wrap-content width-l introduce">
        <div>
          <h2>
            안녕하세요. <br />
            좋아하는 일을 하는 <br />
            웹 개발자 이혜민입니다.
          </h2>
          <p>
            Figma를 활용하여 UI 디자인을 하고, Vue.js, PHP, HTML5, CSS3, Javascript를 활용하여 웹 개발을 합니다.<br />
            저의 취미는 만들기입니다. 공예디자인을 전공하여 손으로 만드는 것을 좋아합니다.<br />
            뿐만 아니라, 온라인에서 코드로 뚝딱뚝딱 만드는 것도 무척이나 좋아합니다.<br />
            저의 강점은 소통과 성장에 대한 열정입니다.<br />
            기획자, 디자이너와 소통이 중요하다고 생각합니다. 원하는 목적을 이룰 수 있도록 디자인 경험을 바탕으로
            경청하고, 이견을 조율합니다.<br />
            그리고 개발자는 끊임없는 공부와 성장을 필수로 하는 직업이라고 생각합니다.<br />
            일하며 배우는 재미와 성장하는 저를 보며 뿌듯하고 흥미로웠습니다. 개발은 제게 단순히 돈을 벌기 위한 수단이
            아닌, 제 삶의 일부분이 되었습니다. 지속해서 발전하고 변화하는 업계의 특징에 발맞추어 스스로 성장하고,
            사용자에게 좋은 서비스를 제공하는 개발자가 되고 싶습니다.
          </p>
        </div>
        <div>
          <ul class="links">
            <li>
              <span class="fs-0 f-border">#Github</span
              ><a
                href="https://github.com/Rosie-bb"
                class="fs-3 mr-2"
                target="_blank"
                >Rosie-bb</a
              >
            </li>
            <li>
              <span class="fs-0 f-border">#work</span
              ><span
                href="https://github.com/Rosie-bb"
                class="fs-3 m-0"
                target="_blank"
                >4</span
              >
            </li>
            <li><span class="fs-0 f-border">#Age</span><span class="fs-3">{{ calculateAge(new Date('1991-05-02')) }}</span></li>
            <!-- <li>
              <span class="fs-0 f-border">#Email</span
              ><a
                href="mailto:lee@hyemin.kr"
                class="fs-3"
                target="_blank"
                >lee@hyemin.kr</a
              >
            </li> -->
          </ul>
        </div>
      </section>

      <!-- 기술 소개 -->
      <section class="width-fluid bg-gray-10 tech">
        <!-- <ul>
            <li class="fs-0 f-border">#Markup</li>
            <li class="fs-0 f-border">#Frontend</li>
            <li class="fs-0 f-border">#Design</li>
        </ul> -->
        <div class="wrap-content width-m align-items-center">
          <div>
            <h2>
              제가 잘 할 수 있는 <br />
              기술을 소개합니다.
            </h2>
            <ul>
              <li>HTML, CSS, JS를 이해한 웹 퍼블리싱</li>
              <li>웹표준을 바탕으로 개발하는 시멘틱 마크업</li>
              <li>반응형 및 인터렉션이 가능한 웹 개발</li>
              <li>Vue.js, CodeIgniter Framework 사용 경험</li>
              <li>Git을 활용한 프로젝트 생성 및 유지보수</li>
              <li>디자인 관련 툴(Figma, Sketch, Adobe tool)을 사용한 UI 개발</li>
            </ul>
          </div>
          <div class="z-index-10">
            <img
              src="@/assets/image/tech.png"
              alt="기술"
            />
          </div>
        </div>
      </section>

      <!-- 자격증 -->
      <section class="wrap-content text-center flex-column">
        <div class="mb-5">
          <h2>Certificate</h2>
        </div>

        <div class="d-flex m-auto">
          <img class="mr-1 cetificate" src="@/assets/image/certificate02.png" alt="웹디자인기능사">
          <img class="cetificate" src="@/assets/image/certificate01.png" alt="그래픽기술자격증">
        </div>
      </section>

      <!-- 프로젝트 소개 -->
      <section class="wrap-content text-center flex-column">
        <div>
          <h2>제가 참여한 프로젝트입니다.</h2>
          <span class="font-gray-100">클릭하시면, 자세히 볼 수 있어요.</span>
        </div>
      </section>

      <div class="wrap-project">
        <div data-aos="fade-up" :data-aos-duration="item.speed" class="inner-project" v-for="(item, index) in project" :key="item">
          <button
              class="bg-transparent border-0"
              data-bs-toggle="modal"
              data-bs-target="#modal"
              @click="selectedProject = index"
            ><i :class="item.color">{{ item.icon }}</i></button>
            <span class="fs-5 p-1">{{ item.title }}</span>
            <div class="font-gray-100 p-1">
              <sapn
                class="p-1"
                v-for="s in item.stack"
                :key="s"
                >#{{ s }}</sapn
              >
            </div>
            <sapn class="contribution font-blue-100">Contribution {{ item.contribution }}%</sapn>
            <span class="f-border tag" :data-aos="item.aos" data-aos-duration="1000">{{ item.tag }}</span>
        </div>
      </div>
     
      <ModalComponent :content="project[selectedProject]"></ModalComponent>
    </div>
  </main>
</template>

<script>
import { ref } from 'vue';
import ModalComponent from '@/components/ModalComponent';

const project = [
// {
//     idx: 0,
//     speed: 500,
//     color: 'bg-yellow-100 font-white-100',
//     icon: 'K',
//     title: '',
//     stack: ['개발','디자인'],
//     contribution: '100',
//     description: '크몽',
//     tech: [
//       'https://img.shields.io/badge/HTML5-F05032?style=for-the-badge&logo=html5&logoColor=ffffff',
//       'https://img.shields.io/badge/CSS3-007ACC?style=for-the-badge&logo=css3&logoColor=ffffff',
//       'https://img.shields.io/badge/-JavaScript-%23F7DF1C?style=for-the-badge&logo=javascript&logoColor=000000&labelColor=%23F7DF1C&color=%23FFCE5A',
//     ],
//     url: 'https://kmong.com/gig/483641',
//     tag: 'Web',
//     aos: 'flip-right',
// },
// {
//     idx: 0,
//     speed: 500,
//     color: 'bg-yellow-100 font-white-100',
//     icon: 'I',
//     title: '',
//     stack: ['개발','디자인'],
//     contribution: '100',
//     description: '교육 웹사이트',
//     tech: [
//       'https://img.shields.io/badge/HTML5-F05032?style=for-the-badge&logo=html5&logoColor=ffffff',
//       'https://img.shields.io/badge/CSS3-007ACC?style=for-the-badge&logo=css3&logoColor=ffffff',
//       'https://img.shields.io/badge/-JavaScript-%23F7DF1C?style=for-the-badge&logo=javascript&logoColor=000000&labelColor=%23F7DF1C&color=%23FFCE5A',
//     ],
//     url: 'https://rosie-bb.github.io/ivyenglish',
// },
{
    idx: 0,
    speed: 1000,
    color: 'bg-blue-50 font-white-100',
    icon: 'C',
    title: 'Chatbot FO',
    stack: ['퍼블리싱'],
    contribution: '100',
    description: '챗봇 사용자 화면',
    tech: [
      'https://img.shields.io/badge/HTML5-F05032?style=for-the-badge&logo=html5&logoColor=ffffff',
      'https://img.shields.io/badge/CSS3-007ACC?style=for-the-badge&logo=css3&logoColor=ffffff',
      'https://img.shields.io/badge/-JavaScript-%23F7DF1C?style=for-the-badge&logo=javascript&logoColor=000000&labelColor=%23F7DF1C&color=%23FFCE5A',
    ],
    video: {
      name: 'chat.aicess.ai',
      width: '100',
    },
    tag: 'Web',
    aos: 'flip-right',
},
{
    idx: 0,
    speed: 1000,
    color: 'bg-blue-50 font-white-100',
    icon: 'C',
    title: 'Chatbot BO',
    stack: ['퍼블리싱'],
    contribution: '80',
    description: '챗봇 관리자 화면',
    tech: [
      'https://img.shields.io/badge/HTML5-F05032?style=for-the-badge&logo=html5&logoColor=ffffff',
      'https://img.shields.io/badge/CSS3-007ACC?style=for-the-badge&logo=css3&logoColor=ffffff',
      'https://img.shields.io/badge/-JavaScript-%23F7DF1C?style=for-the-badge&logo=javascript&logoColor=000000&labelColor=%23F7DF1C&color=%23FFCE5A',
    ],
    video: {
      name: 'chat-admin.aicess.ai',
      width: '100',
    }
},
{
    idx: 0,
    speed: 1000,
    color: 'bg-blue-100 font-white-100',
    icon: 'D',
    title: 'D-CAB BO',
    stack: ['퍼블리싱'],
    contribution: '100',
    description: '기업 웹사이트 관리자 화면',
    tech: [
      'https://img.shields.io/badge/HTML5-F05032?style=for-the-badge&logo=html5&logoColor=ffffff',
      'https://img.shields.io/badge/CSS3-007ACC?style=for-the-badge&logo=css3&logoColor=ffffff',
      'https://img.shields.io/badge/-JavaScript-%23F7DF1C?style=for-the-badge&logo=javascript&logoColor=000000&labelColor=%23F7DF1C&color=%23FFCE5A',
    ],
    url: 'https://rosie-bb.github.io/d-cab/admin/personal/list.html',
},
{
    idx: 0,
    speed: 500,
    color: 'bg-blue-100 font-white-100',
    icon: 'D',
    title: 'D-CAB FO',
    stack: ['퍼블리싱'],
    contribution: '100',
    description: '기업 웹사이트',
    tech: [
      'https://img.shields.io/badge/HTML5-F05032?style=for-the-badge&logo=html5&logoColor=ffffff',
      'https://img.shields.io/badge/CSS3-007ACC?style=for-the-badge&logo=css3&logoColor=ffffff',
      'https://img.shields.io/badge/-JavaScript-%23F7DF1C?style=for-the-badge&logo=javascript&logoColor=000000&labelColor=%23F7DF1C&color=%23FFCE5A',
    ],
    url: 'https://rosie-bb.github.io/d-cab/web/index.html',
},
{
    idx: 0,
    speed: 500,
    color: 'bg-pink-100 font-white-100',
    icon: 'W',
    title: '바이우주',
    stack: ['퍼블리싱'],
    contribution: '100',
    description: '바이우주 랜딩페이지',
    tech: [
      'https://img.shields.io/badge/HTML5-F05032?style=for-the-badge&logo=html5&logoColor=ffffff',
      'https://img.shields.io/badge/CSS3-007ACC?style=for-the-badge&logo=css3&logoColor=ffffff',
      'https://img.shields.io/badge/-JavaScript-%23F7DF1C?style=for-the-badge&logo=javascript&logoColor=000000&labelColor=%23F7DF1C&color=%23FFCE5A',
    ],
    url: 'https://woozo.hyemin.kr/',
    picture: {
      url: 'woozo.jpg',
      width: '100',
    },
},
// {
//     idx: 0,
//     speed: 1000,
//     color: 'bg-blue-100 font-white-100',
//     icon: 'O',
//     title: '선박 Admin',
//     stack: ['디자인', '개발'],
//     contribution: '100',
//     description: '선박 운영 관리 사이트',
//     tech: [
//       'https://img.shields.io/badge/HTML5-F05032?style=for-the-badge&logo=html5&logoColor=ffffff',
//       'https://img.shields.io/badge/CSS3-007ACC?style=for-the-badge&logo=css3&logoColor=ffffff',
//       'https://img.shields.io/badge/-JavaScript-%23F7DF1C?style=for-the-badge&logo=javascript&logoColor=000000&labelColor=%23F7DF1C&color=%23FFCE5A',
//       'https://img.shields.io/badge/-Tailwind-%23F7DF1C?style=for-the-badge&logo=Tailwind&color=%230EA5E9',
//       'https://img.shields.io/badge/-Vue-1A1A1A?style=for-the-badge&logo=vue.js',
//     ],
//     url: 'https://oceanbit-admin.hyemin.kr/',
//     video: {
//       name: 'oceanbit-admin.hyemin.kr',
//       width: '100',
//     },
//     tag: 'Markup',
//     aos: 'flip-down',
// },
// {
//     idx: 1,
//     speed: 2000,
//     color: 'bg-blue-100 font-white-100',
//     icon: 'O',
//     title: '선박',
//     stack: ['디자인', '개발'],
//     contribution: '100',
//     description: '선박 운영 사이트',
//     tech: [
//       'https://img.shields.io/badge/HTML5-F05032?style=for-the-badge&logo=html5&logoColor=ffffff',
//       'https://img.shields.io/badge/CSS3-007ACC?style=for-the-badge&logo=css3&logoColor=ffffff',
//       'https://img.shields.io/badge/-JavaScript-%23F7DF1C?style=for-the-badge&logo=javascript&logoColor=000000&labelColor=%23F7DF1C&color=%23FFCE5A',
//       'https://img.shields.io/badge/-Tailwind-%23F7DF1C?style=for-the-badge&logo=Tailwind&color=%230EA5E9',
//       'https://img.shields.io/badge/-Vue-1A1A1A?style=for-the-badge&logo=vue.js',
//     ],
//     url: 'https://oceanbit.hyemin.kr/',
//     video: {
//       name: 'oceanbit.hyemin.kr',
//       width: '100',
//     },
//     tag: 'Developer',
//     aos: 'fade-left',
//   },
  {
    idx: 2,
    speed: 500,
    color: 'bg-purple-100 font-white-100',
    icon: 'S',
    title: '쇼핑몰',
    stack: ['디자인', '퍼블리싱'],
    contribution: '50',
    description: '건강기능식품 판매 쇼핑몰',
    tech: [
      'https://img.shields.io/badge/HTML5-F05032?style=for-the-badge&logo=html5&logoColor=ffffff',
      'https://img.shields.io/badge/CSS3-007ACC?style=for-the-badge&logo=css3&logoColor=ffffff',
      'https://img.shields.io/badge/-JavaScript-%23F7DF1C?style=for-the-badge&logo=javascript&logoColor=000000&labelColor=%23F7DF1C&color=%23FFCE5A',
      'https://img.shields.io/badge/-PHP-333333?style=for-the-badge&logo=php',
      'https://img.shields.io/badge/-Codeigniter-404040?style=for-the-badge&logo=Codeigniter',
    ],
    // url: 'https://app.mohe.store',
    video: {
      name: 'app.mohe.store',
      width: '30',
    },
  },
  {
    idx: 3,
    speed: 2000,
    color: 'bg-orange-100',
    icon: 'M',
    title: '브랜드 웹사이트',
    stack: ['퍼블리싱'],
    contribution: '80',
    description: '브랜드 홍보용 웹사이트',
    tech: [
      'https://img.shields.io/badge/HTML5-F05032?style=for-the-badge&logo=html5&logoColor=ffffff',
      'https://img.shields.io/badge/CSS3-007ACC?style=for-the-badge&logo=css3&logoColor=ffffff',
      'https://img.shields.io/badge/-JavaScript-%23F7DF1C?style=for-the-badge&logo=javascript&logoColor=000000&labelColor=%23F7DF1C&color=%23FFCE5A',
      'https://img.shields.io/badge/-PHP-333333?style=for-the-badge&logo=php',
      'https://img.shields.io/badge/-Codeigniter-404040?style=for-the-badge&logo=Codeigniter',
    ],
    // url: 'https://mohe.life',
    video: {
      name: 'mohe.life',
      width: '100',
    },
    tag: 'Markup',
    aos: 'flip-right',
  },
  {
    idx: 4,
    speed: 500,
    color: 'bg-blue-50',
    icon: 'M',
    title: '웹사이트 관리자',
    stack: ['디자인', '퍼블리싱'],
    contribution: '80',
    description: '웹사이트 관리하기 위한 대시보드',
    tech: [
      'https://img.shields.io/badge/-Vue-1A1A1A?style=for-the-badge&logo=vue.js',
      'https://img.shields.io/badge/HTML5-F05032?style=for-the-badge&logo=html5&logoColor=ffffff',
      'https://img.shields.io/badge/CSS3-007ACC?style=for-the-badge&logo=css3&logoColor=ffffff',
      'https://img.shields.io/badge/-JavaScript-%23F7DF1C?style=for-the-badge&logo=javascript&logoColor=000000&labelColor=%23F7DF1C&color=%23FFCE5A',
      'https://img.shields.io/badge/-Tailwind-%23F7DF1C?style=for-the-badge&logo=Tailwind&color=%230EA5E9',
    ],
    video: {
      name: 'admin.mohe.life',
      width: '100',
    }
  },
  {
    idx: 5,
    speed: 1000,
    color: 'bg-blue-dark-100 font-white-100',
    icon: 'M',
    title: '대기열 관리자',
    stack: ['디자인', '퍼블리싱'],
    contribution: '80',
    description: '리모컨 컨셉의 오프라인 매장에서 대기열을 관리하는 웹사이트',
    tech: [
      'https://img.shields.io/badge/HTML5-F05032?style=for-the-badge&logo=html5&logoColor=ffffff',
      'https://img.shields.io/badge/CSS3-007ACC?style=for-the-badge&logo=css3&logoColor=ffffff',
      'https://img.shields.io/badge/-JavaScript-%23F7DF1C?style=for-the-badge&logo=javascript&logoColor=000000&labelColor=%23F7DF1C&color=%23FFCE5A',
      'https://img.shields.io/badge/-PHP-333333?style=for-the-badge&logo=php',
      'https://img.shields.io/badge/-Codeigniter-404040?style=for-the-badge&logo=Codeigniter',
      'https://img.shields.io/badge/-Bootstrap-0d6efd?style=for-the-badge&logo=Bootstrap&logoColor=ffffff',
    ],
    video: {
      name: 'offie.mohe.store',
      width: '30',
    },
  },
  {
    idx: 4,
    speed: 500,
    color: 'bg-blue-100 font-white-100',
    icon: 'P',
    title: '웹사이트',
    stack: ['디자인', '퍼블리싱'],
    contribution: '100',
    description: '비즈니스 웹사이트',
    tech: [
      'https://img.shields.io/badge/HTML5-F05032?style=for-the-badge&logo=html5&logoColor=ffffff',
      'https://img.shields.io/badge/CSS3-007ACC?style=for-the-badge&logo=css3&logoColor=ffffff',
      'https://img.shields.io/badge/-JavaScript-%23F7DF1C?style=for-the-badge&logo=javascript&logoColor=000000&labelColor=%23F7DF1C&color=%23FFCE5A',
      'https://img.shields.io/badge/-PHP-333333?style=for-the-badge&logo=php',
      'https://img.shields.io/badge/-Codeigniter-404040?style=for-the-badge&logo=Codeigniter',
    ],
    // url: 'https://philodata.io/',
    video: {
      name: 'philodata.io',
      width: '100',
    },
  },
];

export default {
  components: {
    ModalComponent,
  },
  setup() {
    const selectedProject = ref(false);
    // const onSwiper = (swiper) => {
    //   console.log(swiper);
    // };
    // const onSlideChange = () => {
    //   console.log('slide change');
    // };
    return {
      // onSwiper,
      // onSlideChange,
      selectedProject,
      // modules: [Scrollbar, Autoplay],
    };
  },
  data() {
    return {
      project,
    };
  },
  methods: {
    calculateAge(birthDate) {

      // 생년월일을 '년', '월', '일'로 분리합니다.
      var birthYear = birthDate.getFullYear();
      var birthMonth = birthDate.getMonth();
      var birthDay = birthDate.getDate();

      // 현재 날짜를 가져옵니다.
      var currentDate = new Date();
      var currentYear = currentDate.getFullYear();
      var currentMonth = currentDate.getMonth();
      var currentDay = currentDate.getDate();

      // 만 나이를 계산합니다.
      var age = currentYear - birthYear;

      // 현재 월과 생일의 월을 비교합니다.
      if (currentMonth < birthMonth) {
        age--;
      }
      // 현재 월과 생일의 월이 같은 경우, 현재 일과 생일의 일을 비교합니다.
      else if (currentMonth === birthMonth && currentDay < birthDay) {
        age--;
      }

        return age;
      }
  },
};
</script>

<style scoped>
/* 프로젝트 소개 */
.wrap-project {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 80px;
}
.inner-project {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 200px;
  position: relative;
}

.inner-project .tag{
  position: absolute;
  z-index: -1;
}
.inner-project:nth-child(1) .tag {
  right: 10%;
  top: 50%;
  font-size: 80px;
}
.inner-project:nth-child(3) .tag {
  left: 10%;
  top: 50%;
  font-size: 80px;
}

.inner-project:nth-child(6) .tag {
  left: 10%;
  top: -30%;
  font-size: 80px;
}

.inner-project:nth-child(7) .tag {
  right: -40%;
  top: -10%;
  font-size: 80px;
}
.inner-project:last-of-type {
  margin-bottom: 0;
}
.inner-project > button {
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
  padding: 0;
}

.inner-project:nth-child(4n - 1){
  padding-left: 150px;
}
.inner-project:nth-child(4n){
  padding-left: 150px;
}
.inner-project:nth-child(2n){
  padding-top: 50px;
}

.inner-project > button i{
  width: 100%;
  height: 100%;
  border-radius: 15px;
  display: flex;
  align-items: center;
  transition: 0.5s;
  justify-content: center;
  box-shadow: 10px 20px 20px rgba(0, 0, 0, 0.2);
}
.inner-project > button:hover i{
  box-shadow: 10px 20px 20px rgba(0, 0, 0, 0.5);
  filter: brightness(120%);
}

/* 자격증 */
.cetificate{width: 200px;}

/* 반응형 */
@media (max-width: 500px) {
  /* 자격증 */
  .cetificate{
    width: 50%;
  }
}
@media (max-width: 768px) {
  .inner-project {
    width: 100%;
    margin-bottom: 100px;
  }
  .inner-project:nth-child(4n - 1){
    padding-left: 0;
  }
  .inner-project:nth-child(4n){
    padding-left: 0;
  }
  .inner-project:nth-child(2n){
    padding-top: 0;
  }
  .inner-project:nth-child(1n){
    padding-right: 50px;
  }
  .inner-project:nth-child(2n){
    padding-left: 100px;
  }
  .inner-project .tag{
    text-indent: -9999px !important;
    font-size: 0 !important;
  }
}
</style>
